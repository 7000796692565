import React, { useState } from "react";
import {
  Box,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Button,
  IconButton,
  Typography,
} from "@mui/material";

import { Icon } from "@iconify/react";

import DatePickerComponent from "src/components/UI/DatePicker";

const largeScreenCols = 2;

function Sort({ closeSort, isLoading, searchHanler }) {
  const [query, setQuery] = useState({
    reward: "none",
    referral: "none",
    from: "",
    cashback: "none",
    to: "",
  });

  const handleChange = (key, value) => {
    setQuery((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const applySort = () => {
    // убрать none из query
    const newQuery = Object.fromEntries(
      Object.entries(query).filter(
        ([key, value]) => value !== "none" && value !== ""
      )
    );
    console.log(newQuery, "newQuery");
    searchHanler(newQuery);
  };

  return (
    <Card sx={{ py: 3, px: 4, mt: 2 }}>
      <Box sx={{ mb: 4, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="body2">
          Запрос на сортировку включает в себя фильтрацию по статусу и сайту
          выше.
        </Typography>
        <IconButton
          onClick={closeSort}
          sx={{ borderRadius: 1, ml: 1 }}
          title="Закрыть"
          variant="contained"
          color="error"
        >
          <Icon icon="ic:round-clear" />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        {" "}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={largeScreenCols}>
            <Box>
              <FormControl size="small" id="reward" fullWidth>
                <InputLabel>Реф. программа</InputLabel>
                <Select
                  size="small"
                  label="Реф. программа"
                  labelId="referral"
                  id="referral"
                  value={query.referral}
                  onChange={(e) => handleChange("referral", e.target.value)}
                >
                  <MenuItem value="asc">По возрастанию</MenuItem>
                  <MenuItem value="desc">По убыванию</MenuItem>
                  <MenuItem value="none">Без сортировки</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={largeScreenCols}>
            <Box>
              <FormControl size="small" id="reward" fullWidth>
                <InputLabel>Кэшбек</InputLabel>
                <Select
                  size="small"
                  label="Кэшбек"
                  labelId="cashback"
                  id="cashback"
                  value={query.cashback}
                  onChange={(e) => handleChange("cashback", e.target.value)}
                >
                  <MenuItem value="asc">По возрастанию</MenuItem>
                  <MenuItem value="desc">По убыванию</MenuItem>
                  <MenuItem value="none">Без сортировки</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={largeScreenCols}>
            <Box>
              <FormControl size="small" id="reward" fullWidth>
                <InputLabel>Вознаг. (Реф. и Кэшбек)</InputLabel>
                <Select
                  size="small"
                  label="Вознаг. (Реф. и Кэшбек)"
                  labelId="reward"
                  id="reward"
                  value={query.reward}
                  onChange={(e) => handleChange("reward", e.target.value)}
                >
                  <MenuItem value="asc">По возрастанию</MenuItem>
                  <MenuItem value="desc">По убыванию</MenuItem>
                  <MenuItem value="none">Без сортировки</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={largeScreenCols}>
            <DatePickerComponent
              value={query.from}
              label="От"
              onChange={(v) => handleChange("from", v)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={largeScreenCols}>
            <DatePickerComponent
              value={query.to}
              label="До"
              onChange={(v) => handleChange("to", v)}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={isLoading}
          startIcon={
            isLoading ? (
              <Icon icon="ri:loader-fill" />
            ) : (
              <Icon icon="icon-park-outline:find" />
            )
          }
          onClick={applySort}
        >
          Применить
        </Button>
      </Box>
    </Card>
  );
}

export default React.memo(Sort);
