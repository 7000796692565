import { Box } from "@mui/material";
import InfoRaw from "./InfoRaw";
import ChargeRefToggle from "./Modules/ChargeRefToggle";
import Charge from "./Modules/Charge";
import PermissionProvider from "src/permission/PermissionProvider";

function ReferralAbout({ order }) {
  // Cтарая логика
  //

  // новая логика

  let referrerClient = order?.referral || order?.client?.referrer;

  // проверить что объект referrerClient не пустой
  let isNotEmptyReferrerClient =
    referrerClient && Object.keys(referrerClient).length > 0;

  if (!isNotEmptyReferrerClient) return null;

  // только для старой версии
  // выполнена есть реферал но нет суммы - выходим
  if (order.status === 105 && !order?.charge?.referralSum) return null;

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <InfoRaw
          iconName="material-symbols:supervised-user-circle-outline"
          text={referrerClient.email}
          style={{ mb: 0 }}
          link={`/clients/${referrerClient._id}`}
        />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {order.status !== 105 && (
            <ChargeRefToggle
              clientId={order.client._id}
              initDncr={order.client.dncr}
            />
          )}
          <PermissionProvider name="order.view-referral-accrual">
            {!!order?.charge?.referralSum && (
              <Charge
                typ="referral"
                orderId={order._id}
                originSum={order?.charge?.referralSum}
              />
            )}
          </PermissionProvider>
        </Box>
      </Box>
    </div>
  );
}

export default ReferralAbout;
