import { Link as MuiLink, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";

import { Icon } from "@iconify/react";

function InfoRaw(props) {
  let {
    style,
    textColor,
    link,
    external = false,
    iconName,
    text,
    TypographyProps = {},
    copy = false,
  } = props;

  let textComponent;

  if (external) {
    textComponent = (
      <MuiLink
        component={"a"}
        target="_blank"
        color="primary"
        underline="none"
        rel="noreferrer"
        variant="body1"
        href={link}
        {...TypographyProps}
      >
        {text}
      </MuiLink>
    );
  } else {
    textComponent = (
      <Typography
        color={link ? "primary" : textColor}
        component={link ? Link : undefined}
        to={link}
        {...TypographyProps}
      >
        {text}
      </Typography>
    );
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: 2, ...style }}>
      <Icon fontSize={20} icon={iconName} />
      <Box sx={{ ml: 1.5 }}>{textComponent}</Box>
    </Box>
  );
}

export default InfoRaw;
