import React from 'react';
// Mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";


// ** Icon
import Icon from "src/@core/components/icon";
import { useAuth } from "src/hooks/useAuth";

function Control(props) {
  let {
    search,
    setSearch,
    openAdvancedFilter,
    openSort,
    isSortOpen,
    isMultiFilter,
    isAdvancedFilter,
    clearAdvancedFilters,
  } = props;

  const { isAdmin } = useAuth();

  return (
    <Box sx={{ mt: 3 }}>
      <Grid justifyContent="space-between" container spacing={4}>
        <Grid item xs={6} md={2}></Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
            {isAdmin &&  <IconButton
              // color="primary"
              variant="contained"
              title="Сортировка"
              onClick={openSort}
              sx={{
                borderRadius: 1,
              }}
            >
              <Icon size="24px" icon="iconoir:sort" />
            </IconButton>}
           

            <IconButton
              color={isMultiFilter ? "primary" : undefined}
              variant="contained"
              title="Расширенный поиск"
              onClick={openAdvancedFilter}
              sx={{
                borderRadius: 1,
              }}
            >
              <Icon size="24px" icon="ic:round-manage-search" />
            </IconButton>

            <TextField
              placeholder="Номер заявки или email клиента"
              sx={{ ml: 2, flexGrow: 1 }}
              size="small"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />



            {isAdvancedFilter && (
              <IconButton
                color="error"
                variant="contained"
                title="Очистить филтры"
                onClick={clearAdvancedFilters}
                sx={{
                  borderRadius: 1,
                  ml: 2,
                }}
              >
                <Icon size="24px" icon="ic:round-clear" />
              </IconButton>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default React.memo(Control);
