import React, { useEffect, useState } from "react";
// Router
import { useNavigate } from "react-router-dom";
// Mui
import {
  Box,
  Grid,
  Card,
  CardContent,
  Divider,
  Typography,
  Avatar,
  IconButton,
  CardActionArea,
  Skeleton,
} from "@mui/material";
//Custom components
import CustomAvatar from "src/@core/components/mui/avatar";

import { useGlobalNotification } from "src/hooks/useGlobalNotification";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Configs
import WEBSITES from "src/configs/websites";

import { socket } from "src/socket";
// Libs
import moment from "moment";

// Config
import ConfigApi from "src/configs/api";

import { toast } from "react-hot-toast";

// Modules
import OrderChip from "../OrderChip";
import Timer from "../Timer";

import OrderService from "src/service/Order";

function OrderCard(props) {
  let { data, visibleArchived = false } = props;
  let [order, setOrder] = useState(data);
  let [showArchived, setShowArchived] = useState(false);

  const updateHandler = (upd) => {
    setOrder((o) => Object.assign(o, upd));
  };

  useEffect(() => {
    socket.on(`order_upd_${data._id}`, updateHandler);
    return () => {
      socket.off(`order_upd_${data._id}`, updateHandler);
    };
  }, []);

  let status = order.status;

  let inWorkStatus = status == 104 && order.onWork;

  // Router
  const navigate = useNavigate();

  const { findByOrderId } = useGlobalNotification();

  const notifyData = findByOrderId(order._id);

  let kitName = order?.incomeInfo?.kitName || "-";

  let infoList = [
    {
      icon: "la:exchange-alt",
      alt: "Курс",
      name: order.rate,
    },

    {
      icon: "mdi:user-circle-outline",
      alt: "Клиент",
      name: order.client.email,
    },
    {
      icon: "ion:wallet-outline",
      alt: "Клиент",
      name: `Комплект: ${kitName}`,
    },
  ];

  const archivedHandler = (event) => {
    event.stopPropagation();
    let archived = order.archived;
    OrderService.changeArchive(order._id).then(() => {
      toast.success(
        `Заявка ${archived ? "разархивированна" : "заархивирована"}`
      );
    });
  };

  let archived = order.archived;

  let timeComponent;

  if (order.status <= 103) {
    timeComponent = (
      <Timer
        TypographyProps={{
          variant: "caption",
          sx: {
            fontWeight: 500,
            // ml: 1,
          },
        }}
        iconFontSize="16px"
        finished={order.finished}
        iconColor="primary"
        iconName="basil:user-solid"
      />
    );
  } else if (order.status == 104 && order.sendExpireDate) {
    timeComponent = (
      <Timer
        iconFontSize="16px"
        TypographyProps={{
          variant: "caption",
          sx: {
            fontWeight: 500,
            // ml: 1,
          },
        }}
        finished={order.sendExpireDate}
        iconName="eos-icons:admin-outlined"
        iconColor="error"
      />
    );
  }

  return (
    <Card
      onClick={() => {
        navigate(`?orderId=${order._id}`);
      }}
      onMouseOver={() => setShowArchived(true)}
      onMouseOut={() => setShowArchived(false)}
    >
      <CardActionArea>
        <CardContent sx={{ py: 3, px: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                "& svg": {
                  color: (t) => t.palette.primary.light,
                },
              }}
            >
              <Typography sx={{ fontSize: "18px", mr: 1 }} variant="body1">
                {order._id}
              </Typography>
              {!!notifyData && <Icon icon="jam:alert" fontSize="18px" />}
              <Box
                sx={{
                  "& svg": {
                    color: (t) => t.palette.secondary.light,
                  },
                }}
              >
                {order.isProblematic && (
                  <Icon
                    icon="material-symbols:problem-outline-rounded"
                    fontSize="18px"
                  />
                )}
              </Box>
            </Box>

            <OrderChip size="small" status={inWorkStatus ? "onwork" : status} />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 1.5,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", mt: 1.5 }}>
              <Icon fontSize={18} icon={"dashicons:admin-site-alt3"} />
              <Typography sx={{ ml: 1.5 }} variant="body2">
                {WEBSITES.find((s) => s.id === order.sc)?.name}
              </Typography>
            </Box>
            {(showArchived || visibleArchived) && (
              <IconButton
                color={archived ? "error" : "primary"}
                onClick={archivedHandler}
                sx={{ p: 1, borderRadius: 1 }}
              >
                <Icon
                  fontSize={18}
                  icon={archived ? "carbon:view-off" : "carbon:view"}
                />
              </IconButton>
            )}
          </Box>
          <Info list={infoList} />
        </CardContent>
        <Divider sx={{ my: "0px !important" }} />
        <CardContent sx={{ py: 2, px: 3 }}>
          <CurrencyInfo
            currency={order.currency}
            take={order.amount.take}
            give={order.amount.give}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: !!timeComponent ? "space-between" : "flex-end",
              mt: 2,
              mb: 2,
            }}
          >
            {timeComponent}

            <Typography
              sx={{
                alignSelf: "flex-end",
              }}
              variant="caption"
            >
              {moment(order.createdAt).format("HH:mm DD.MM.YYYY")}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

function Info(props) {
  let { list } = props;

  return (
    <Box sx={{ mb: 2.5, color: "secondary" }}>
      {list.map((i, p) => {
        return (
          <Box key={p} sx={{ display: "flex", alignItems: "center", mt: 2.5 }}>
            <Icon fontSize={18} icon={i.icon} />
            <Typography sx={{ ml: 1.5 }} variant="body2">
              {i.name}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}

function CurrencyInfo(props) {
  let { currency, take, give } = props;

  let curTake = currency.take;
  let curGive = currency.give;

  return (
    <Box>
      <CurrencyInfoItem
        name={curTake.name}
        code={curTake.code}
        sum={take}
        image={curTake.image}
      />
      <Box sx={{ ml: 1.5 }}>
        <Icon icon="ph:arrow-down-bold" />
      </Box>
      <CurrencyInfoItem
        name={curGive.name}
        code={curGive.code}
        sum={give}
        image={curGive.image}
      />
    </Box>
  );
}

function CurrencyInfoItem(props) {
  let { name, code, sum, image } = props;
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <CustomAvatar
        skin="light"
        variant="rounded"
        sx={{ width: 38, height: 38, mr: 3 }}
      >
        <Avatar
          src={ConfigApi.PUBLIC_IMAGES + image + ".png"}
          alt={name}
          sx={{ height: 30, width: 30 }}
        />
      </CustomAvatar>
      <Box>
        <Typography
          fontWeight="bold"
          variant="body1"
        >{`${sum} ${code}`}</Typography>
        <Typography noWrap variant="body2">
          {name}
        </Typography>
      </Box>
    </Box>
  );
}

export default React.memo(OrderCard);

const OrderCardSkeleton = () => {
  return (
    <Card>
      <CardContent sx={{ py: 3, px: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Skeleton variant="rounded" width={120} height={27} />
          <Skeleton variant="rounded" width={84} height={27} />
        </Box>

        <Skeleton variant="rounded" width="100%" height={120} sx={{ mt: 3 }} />
      </CardContent>
      <Divider sx={{ my: "0px !important" }} />
      <Box sx={{ py: 2, px: 3 }}>
        <Skeleton
          variant="rounded"
          width="100%"
          height={116}
          // sx={{ mt: 1.5 }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            py: 2,
          }}
        >
          <Skeleton variant="rounded" width={90} height={17} />
        </Box>
      </Box>
    </Card>
  );
};

export { OrderCardSkeleton };
