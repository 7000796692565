import React, { useState, useEffect } from "react";
// Mui
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";

import { WEBSITES_LIST, ORDER_STATUS_LIST } from "./constants";

import { useAuth } from "src/hooks/useAuth";

function Filter(props) {
  let { site, setSite, status, setStatus } = props;

  const { isAdmin } = useAuth();

  const STATUSES = ORDER_STATUS_LIST.filter((s) => {
    if (isAdmin) {
      return true;
    } else {
      return s.value !== "check";
    }
  });
  return (
    <Box>
      <TabContext value={site}>
        <TabList
          variant="scrollable"
          scrollButtons="auto"
          onChange={(e, v) => setSite(v)}
        >
          {WEBSITES_LIST.map((w) => (
            <Tab key={w.value} value={w.value} label={w.name} />
          ))}
        </TabList>
      </TabContext>
      <TabContext value={status}>
        <TabList
          variant="scrollable"
          scrollButtons="auto"
          onChange={(e, v) => setStatus(v)}
        >
          {STATUSES.map((w) => (
            <Tab key={w.value} value={w.value} label={w.name} />
          ))}
        </TabList>
      </TabContext>
    </Box>
  );
}

export default React.memo(Filter);
