import React, { useEffect, useState } from "react";

// Editor
import { Editor } from "@tinymce/tinymce-react";

function CustomEditor({ value, onChange }) {
  const changeContentHandler = (e) => {
    let content = e.target.getContent();
    onChange(content);
  };

  const plugins = [
    "advlist",
    "autolink",
    "lists",
    "link",
    "image",
    "charmap",
    "preview",
    "anchor",
    "searchreplace",
    "visualblocks",
    "code",
    "fullscreen",
    "insertdatetime",
    "media",
    "table",
    "help",
    "wordcount",
    "emoticons",
    "codesample",
    "pagebreak",
    "imagetools",
    "spellchecker",
    "template",
  ];

  const toolbar = `
    undo redo | formatselect | bold italic underline strikethrough |
    alignleft aligncenter alignright alignjustify | bullist numlist outdent indent |
    link image media | forecolor backcolor emoticons | code preview fullscreen
  `;

  return (
    <Editor
      initialValue={value}
      apiKey="xf7tljn1dc3ibxd2os5itf28gfwg9sqoxptp5yyocuusp2o0"
      init={{
        language: "ru",
        branding: false,
        height: 500,
        menubar: true,
        plugins: plugins,
        toolbar: toolbar,
        //   "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
        image_advtab: true,
      }}
      onChange={changeContentHandler}
    />
  );
}
export default CustomEditor;
