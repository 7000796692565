import React, { useState, useEffect } from "react";
// Mui
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";


import { styled } from "@mui/material/styles";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Components
import CurrencySelect from "src/components/CurrencySelect";

// Services
import CurrenciesService from "src/service/Currencies";
import WalletService from "src/service/Wallet";

import sortWallet from "src/helpers/wallet/sortWallet";
import sortCurrencies from 'src/utils/currency/sortCurrencies'

// Modules
import WalletSelect from "src/modules/Wallets/WalletSelect";

const Toggler = styled(Box)(({ theme }) => ({
  right: 0,
  top: "90%",
  display: "flex",
  cursor: "pointer",
  position: "fixed",
  zIndex: theme.zIndex.modal,
  padding: theme.spacing(2.5),
  transform: "translateY(-50%)",
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderBottomLeftRadius: theme.shape.borderRadius,
}));

function WalletQuickView() {
  // ** State
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  return (
    <Box sx={{ position: "relative" }}>
      <Toggler className="customizer-toggler" onClick={() => setOpen(true)}>
        <Icon icon="uil:wallet" fontSize={20} />
      </Toggler>

      <Drawer
        SlideProps={{
          unmountOnExit: true,
        }}
        open={open}
        anchor="right"
        variant="temporary"
        onClose={handleClose}
        sx={{ "& .MuiDrawer-paper": { width: { xs: "100%", sm: 500 } } }}
      >
        <Box
          className="customizer-header"
          sx={{
            position: "relative",
            p: (theme) => theme.spacing(3.5, 5),
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography variant="h6" sx={{ mr: 6 }}>
            Балансы
          </Typography>
          <IconButton
            onClick={() => setOpen(false)}
            sx={{
              right: 20,
              top: "50%",
              position: "absolute",
              color: "text.secondary",
              transform: "translateY(-50%)",
            }}
          >
            <Icon icon="mdi:close" fontSize={20} />
          </IconButton>
        </Box>
        <QuickViewContent />
      </Drawer>
    </Box>
  );
}

function QuickViewContent({ }) {
  const ExpandIcon = <Icon icon={"mdi:chevron-left"} />;

  // States
  let [currencies, setСurrencies] = useState([]);
  let [currency, setCurrency] = useState("");
  let [kits, setKits] = useState([]);

  // Hooks

  // console.log(currencies, 'currencies')

  let code = currencies.find((c) => c._id == currency)?.code;

  if (currency == 'global') {
    code = 'RUB'
  }

  useEffect(() => {
    if (!currency) return;
    WalletService.getBalance(currency).then((r) => {
      setKits(r.data);
    });
  }, [currency]);

  useEffect(() => {
    CurrenciesService.getList({
      populate: ["wallet"],
    }).then((r) => setСurrencies(r.data));
  }, []);

  // let sortedKits = sortWallet(kits);

  return (
    <Box>
      <Box sx={{ p: 3 }}>
        <CurrencySelect
          containerStyle={{ mt: 4 }}
          label="Валюта"
          placeholder="Выберите валюту"
          currencies={[
            {
              _id: "global",
              image: "Общие комплекты",
              name: "Общие комплекты",
            },

            ...sortCurrencies(currencies)
            // ...currencies,
          ]}
          value={currency}
          showBalance
          setValue={(v) => setCurrency(v)}
        />
      </Box>

      <WalletSelect smallText kits={kits} code={code} />
    </Box>
  );
}

export default React.memo(WalletQuickView);
