import { forwardRef } from "react";
import moment from "moment";
import "moment/locale/ru";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import DatePicker from "react-datepicker";
// ** Styled Component
import DatePickerWrapper from "src/@core/styles/libs/react-datepicker";
// ** Icon
import Icon from "src/@core/components/icon";

const CustomInput = forwardRef(({ ...props }, ref) => {
  // ** Props
  const { label, readOnly, inputProps = {}, date } = props;

  const formatDate = date ? moment(date).format("DD.MM.yyyy HH:mm") : "";

  return (
    <TextField
      fullWidth
      inputRef={ref}
      InputProps={inputProps}
      {...props}
      label={label || ""}
      {...(readOnly && { inputProps: { readOnly: true, ...inputProps } })}
      value={formatDate}
    />
  );
});

const DatePickerComponent = ({
  value,
  onChange,
  size = "small",
  style = {},
  label = "",
}) => {
  return (
    <DatePickerWrapper
      sx={{ "& .react-datepicker-wrapper": { width: "100%", ...style } }}
    >
      <DatePicker
        showTimeSelect
        locale="ru"
        timeFormat="HH:mm"
        timeIntervals={15}
        selected={value}
        id="dtp-from"
        dateFormat="dd.mm.yyyy HH:mm"
        onChange={(v) => onChange(moment(v).valueOf())}
        customInput={
          <CustomInput
            date={value}
            size={size}
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onChange("");
                    }}
                    edge="end"
                  >
                    <Icon icon="ic:baseline-clear" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label={label}
          />
        }
      />
    </DatePickerWrapper>
  );
};

export default DatePickerComponent;
