import { useState } from "react";
import { useGlobalNotification } from "src/hooks/useGlobalNotification";
// Mui
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import MuiLink from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import { CircularProgress, TextField } from "@mui/material";

// ** Custom Components
import OrderChip from "../OrderChip";
import Timer from "../Timer";
import CustomChip from "src/@core/components/mui/chip";

// Modules
import Reviews from "./Reviews";
import Charge from "./Modules/Charge";

import ClientLoyaltyChange from "src/modules/Clients/ClientLoyalty";
import CopyClipboardWrap from "src/components/UI/CopyClipboardWrap";

// Permission provider
import PermissionProvider from "src/permission/PermissionProvider";

// import { getDomainWithoutZoneFromEmail } from "src/utils/text";
// ** Icon Imports
import Icon from "src/@core/components/icon";

import ReferralAbout from "./ReferralAbout";

// Configs
import WEBSITES from "src/configs/websites";

import { toast } from "react-hot-toast";

// Libs
import moment from "moment";

import { Link, useNavigate } from "react-router-dom";

import History from "./History";

import { globalNotificationService } from "src/service/GlobalNotification";

// Hooks
import { useAuth } from "src/hooks/useAuth";
import { Grid } from "@mui/material";

import OrderService from "src/service/Order";

import InfoRaw from "./InfoRaw";

function OrderInfo(props) {
  let { style = {}, order } = props;

  let [showHistory, setShowHistory] = useState(false);
  let [showReviews, setShowReviews] = useState(false);
  let [submitting, setSubmitting] = useState("");
  let [commentProblematic, setCommentProblematic] = useState("");
  let [problematicShowSetting, setProblematicShowSetting] = useState(false);

  const { findByOrderId } = useGlobalNotification();

  const notifyData = findByOrderId(order._id);
  // Router
  const navigate = useNavigate();

  const { isAdmin } = useAuth();

  let id = order._id;

  let ipInfo = order?.info?.ip;
  let fieldsStamp = order?.info?.fieldsStamp;
  let newClient = order?.info?.newClient;
  let referrer = order?.info?.referrer;
  let reviews = order?.info?.reviews;
  let isProblematic = order?.isProblematic;

  let adminComment = order?.comment?.admin;
  let orderInWork = order?.onWork && order.status == 104;

  let timerComponent;

  // Handlers
  const changeOrderProblematic = (isProblematic) => {
    if (submitting) return;
    // let newIsProblematic = !isProblematic;

    OrderService.changeCommon(id, {
      isProblematic,
      commentProblematic: commentProblematic,
    })
      .then(() => {
        let text = isProblematic
          ? "Заявка помечена как проблемная!"
          : 'Пометка "проблемная" снята!';
        toast.success(text);
      })
      .catch(() => {})
      .finally(() => {
        setSubmitting("");
        setProblematicShowSetting(false);
        setCommentProblematic("");
      });
  };

  if (order.status <= 103) {
    timerComponent = (
      <Box sx={{ display: "flex", my: 3 }}>
        <Timer
          TypographyProps={{
            variant: "h6",
            sx: {
              fontWeight: 500,
            },
          }}
          finished={order.finished}
          iconColor="primary"
          iconName="basil:user-solid"
        />
      </Box>
    );
  } else if (order.status == 104 && order.sendExpireDate) {
    timerComponent = (
      <Box sx={{ display: "flex", my: 3 }}>
        <Timer
          TypographyProps={{
            variant: "h6",
            sx: {
              fontWeight: 500,
            },
          }}
          finished={order.sendExpireDate}
          iconName="eos-icons:admin-outlined"
          iconColor="error"
        />
      </Box>
    );
  }

  const problematicView = (
    <Grid sx={{ mt: 2 }} container spacing={2}>
      {isProblematic && (
        <>
          <Grid item xs={12} md={12}>
            <Box>
              <Typography variant="body2">Комментарий проблемы:</Typography>
            </Box>
            <Typography variant="body1" color="error">
              {order.commentProblematic}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Button
              startIcon={
                submitting == "problematic" ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Icon icon="icon-park-outline:doc-success" />
                )
              }
              variant="outlined"
              color="success"
              fullWidth
              onClick={() => {
                changeOrderProblematic(false);
              }}
            >
              Проблема решена
            </Button>
          </Grid>
        </>
      )}
      {!isProblematic && !problematicShowSetting && (
        <Grid item xs={12} md={12}>
          <Button
            startIcon={
              submitting == "problematic" ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <Icon icon="material-symbols:problem-outline-rounded" />
              )
            }
            variant="outlined"
            color="error"
            fullWidth
            onClick={() => {
              setProblematicShowSetting(true);
            }}
          >
            Проблемная
          </Button>
        </Grid>
      )}
      {!isProblematic && problematicShowSetting && (
        <>
          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
              value={commentProblematic}
              onChange={(e) => setCommentProblematic(e.target.value)}
              type="text"
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Button
              onClick={() => changeOrderProblematic(true)}
              startIcon={
                submitting == "problematic" && (
                  <CircularProgress size={20} color="inherit" />
                )
              }
              variant="contained"
              color="primary"
              fullWidth
            >
              Установить
            </Button>
          </Grid>
          <Grid item xs={6} md={6}>
            <Button
              variant="outlined"
              color="error"
              fullWidth
              onClick={() => {
                setProblematicShowSetting(false);
              }}
            >
              Отменить
            </Button>
          </Grid>
        </>
      )}

      {/* {isProblematic ? (
         
        ) : (
          
        )}
       */}
      {/* </Grid> */}
    </Grid>
  );

  return (
    <CardContent sx={{ ...style }}>
      <History
        open={showHistory}
        handleClose={() => setShowHistory(false)}
        orderId={id}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h5">{id}</Typography>
          <PermissionProvider name="history.one-order">
            <IconButton
              onClick={() => setShowHistory(true)}
              color="primary"
              sx={{ borderRadius: 1, ml: 3 }}
            >
              <Icon icon="material-symbols:history-edu-outline" />
            </IconButton>
          </PermissionProvider>
        </Box>

        <Box>
          {order.onCheck && isAdmin && (
            <OrderChip status={"oncheck"} style={{ mr: 2 }} />
          )}

          {orderInWork && <OrderChip status={"onwork"} style={{ mr: 2 }} />}
          <OrderChip status={order.status} />
        </Box>
      </Box>
      {order.isProblematic && (
        <Box
          sx={{
            mt: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <OrderChip status="problem" />
        </Box>
      )}

      {timerComponent}

      <Box sx={{ mt: 3 }}>
        {order.isTelgramOrder && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
            }}
          >
            {order.telegramUsername && (
              <InfoRaw
                iconName="streamline:telegram"
                text={"@" + order.telegramUsername}
                external
                style={{ mb: 0 }}
                link={`https://t.me/${order.telegramUsername}`}
              />
            )}

            <Box>
              <CustomChip
                size={"large"}
                label={"Telegram заявка"}
                color={"info"}
                icon={<Icon icon="ic:outline-telegram" />}
                sx={{
                  fontWeight: 600,
                  borderRadius: "5px",
                }}
              />
            </Box>
          </Box>
        )}

        <HashLink hash={order.hash} sc={order.sc} />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", flex: "flex-wrap" }}
          >
            <InfoRaw
              iconName="mdi:user-circle-outline"
              text={order.client.email}
              style={{ mb: 0 }}
              link={`/clients/${order.client._id}`}
            />

            <ClientLoyaltyChange
              style={{ ml: 1 }}
              init={order?.client?.loyalty}
              clientId={order?.client?._id}
            />
          </Box>

          <Box>
            {!!order?.charge?.cashbackSum && (
              <Charge
                typ="cashback"
                orderId={order._id}
                originSum={order?.charge?.cashbackSum}
              />
            )}
          </Box>
        </Box>
        <ReferralAbout order={order} />
        <InfoRaw iconName="la:exchange-alt" text={order.rate} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <InfoRaw
            iconName="dashicons:admin-site-alt3"
            text={WEBSITES.find((s) => s.id === order.sc)?.name}
          />
          {newClient && (
            <CustomChip
              skin="light"
              size={"small"}
              label={"Новый клиент"}
              color="success"
              sx={{
                width: "min-content",
                fontSize: "1rem",
                fontWeight: 600,
                borderRadius: "5px",
              }}
            />
          )}
        </Box>
        <InfoRaw
          iconName="ion:time-outline"
          text={moment(order.createdAt).format("DD MMMM YYYY, в HH:mm")}
        />
        {ipInfo && !order.isTelgramOrder && (
          <InfoRaw iconName="fluent:location-16-regular" text={ipInfo} />
        )}
        {reviews && !!reviews.length && (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <InfoRaw iconName="octicon:code-review-16" text="Отзывы" />
              <IconButton
                sx={{ ml: 3 }}
                onClick={() => setShowReviews(!showReviews)}
              >
                <Icon icon="material-symbols:expand-more-rounded" />
              </IconButton>
            </Box>
            <Collapse in={showReviews} timeout="auto" unmountOnExit>
              <Reviews list={reviews} />
            </Collapse>
          </>
        )}
        {referrer && (
          <InfoRaw
            link={referrer}
            iconName="carbon:notebook-reference"
            text={referrer}
            external
          />
        )}
        {adminComment && order.status == 106 && (
          <InfoRaw iconName="ic:outline-comment" text={adminComment} />
        )}

        <InfoRaw
          link={
            order.debt ? `?debt=${order.debt}` : `?debt=new&oid=${order._id}`
          }
          iconName="arcticons:debtcalc"
          text={
            order.debt ? "Посмотреть задолженность" : "Установить задолженность"
          }
        />

        {problematicView}

        {notifyData && (
          <Alert
            onClose={(e) => {
              e.preventDefault();
              globalNotificationService.delete(notifyData._id);
            }}
            sx={{ mt: 4 }}
            icon={false}
            variant="outlined"
            severity="info"
          >
            {notifyData.message}
          </Alert>
        )}
      </Box>
    </CardContent>
  );
}

function HashLink({ hash, sc, style = {} }) {
  let client = WEBSITES.find((s) => s.id == sc)?.client;
  let link = client + "/order/" + hash;
  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: 2, ...style }}>
      <Icon fontSize={20} icon="ph:hash-bold" />
      <MuiLink
        sx={{ ml: 1.5 }}
        component={"a"}
        target="_blank"
        color="primary"
        underline="none"
        rel="noreferrer"
        variant="body1"
        href={link}
      >
        Хэш-ссылка
      </MuiLink>
      <CopyClipboardWrap size="20px" value={link} />
    </Box>
  );
}

export default OrderInfo;
